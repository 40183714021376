import {
  ConnectionStatus,
  createUniversalHexFlashDataSource,
  DeviceError,
  MicrobitWebUSBConnection
} from "@microbit/microbit-connection";
import classNames from "classnames";
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState
} from "react";
import { useDropzone } from "react-dropzone";
import Button from "../../components/Button/StandardButton/Button";
import Text from "../../components/Text/Text";
import VisuallyHidden from "../../components/VisuallyHidden/VisuallyHidden";
import styles from "./HexFlashTool.module.scss";

interface HexFlashToolProps {}

interface Status {
  state:
    | "tbd"
    | "unsupported"
    | "supported"
    | "selected"
    | "permission"
    | "in-progress"
    | "complete"
    | "error";
  message?: ReactNode;
}

interface HexFile {
  name: string;
  data: string;
}

const flash = async (
  webUsb: MicrobitWebUSBConnection,
  file: HexFile,
  setStatus: (status: Status) => void,
  updateProgress: (progress: number) => void
) => {
  setStatus({ state: "permission", message: "Selecting device..." });
  try {
    await webUsb.connect();
    setStatus({ state: "in-progress", message: "Flashing hex file..." });
    await webUsb.flash(createUniversalHexFlashDataSource(file.data), {
      partial: true,
      minimumProgressIncrement: 0.01,
      progress: (v: number | undefined) => updateProgress(v ?? 1)
    });
    setStatus({ state: "in-progress", message: "Disconnecting..." });
    await webUsb.disconnect();
    setStatus({ state: "complete", message: "Flash complete" });
  } catch (err) {
    if (err instanceof DeviceError) {
      switch (err.code) {
        case "no-device-selected":
          return setStatus(supportedStatus(webUsb.status));
        case "clear-connect":
          return setStatus({
            state: "error",
            message: (
              <span>
                Another process is connected to this device. Close any other
                tabs that may be using WebUSB (e.g. MakeCode, Python Editor), or
                unplug and replug the micro:bit before trying again.
              </span>
            )
          });

        default:
          return setStatus({
            state: "error",
            message: (err as any).toString()
          });
      }
    }
  } finally {
    // Always clear device to request device again next time.
    await webUsb.clearDevice();
  }
};

const supportedStatus = (connectionStatus: ConnectionStatus): Status =>
  connectionStatus !== ConnectionStatus.NOT_SUPPORTED
    ? {
        state: "supported",
        message: (
          <span>
            Drag and drop a hex file here
            <br /> or click to browse
          </span>
        )
      }
    : {
        state: "unsupported",
        message: (
          <span>
            WebUSB is not supported by this browser. Try using Google Chrome or
            Microsoft Edge.
          </span>
        )
      };

const HexFlashTool = (_: HexFlashToolProps) => {
  const usb = useRef<MicrobitWebUSBConnection | null>(null);
  const [hexFile, setHexFile] = useState<HexFile | undefined>();
  const [status, setStatus] = useState<Status>({
    state: "tbd",
    message: "Checking WebUSB support..."
  });
  const progressRef = React.useRef<HTMLProgressElement>(null);

  const handleFlash = useCallback(
    async (e: React.MouseEvent<HTMLElement>) => {
      e.stopPropagation();
      if (!hexFile || !usb.current) {
        throw new Error();
      }
      const updateProgress = (progress: number) => {
        // Bypass React to avoid slowdown
        requestAnimationFrame(() => {
          if (progressRef.current) {
            progressRef.current.value = progress;
          }
        });
      };
      await flash(usb.current, hexFile, setStatus, updateProgress);
    },
    [hexFile, progressRef]
  );

  const handleStartAgain = useCallback((e: React.MouseEvent<HTMLElement>) => {
    e.stopPropagation();
    if (usb.current) {
      setStatus(supportedStatus(usb.current?.status));
    }
  }, []);

  const onDrop = useCallback((acceptedFiles: File[]) => {
    if (acceptedFiles.length === 1) {
      const file = acceptedFiles[0];
      if (!file.name.toLowerCase().endsWith(".hex")) {
        setStatus({
          state: "error",
          message: "The chosen file was not a hex file"
        });
      } else {
        const reader = new FileReader();
        reader.onloadend = evt => {
          const hexStr = evt.target?.result;
          if (typeof hexStr === "string") {
            setHexFile({
              name: file.name,
              data: hexStr
            });
            setStatus({
              state: "selected",
              message: `File: ${file.name}`
            });
          }
        };
        reader.readAsText(file);
      }
    }
  }, []);

  useEffect(() => {
    // Deferred to now for SSR.
    if (status.state === "tbd" && !usb.current) {
      usb.current = new MicrobitWebUSBConnection();
      setStatus(supportedStatus(usb.current.status));
    }
  }, [status]);

  const zoneDisabled =
    status.state === "selected" || status.state === "unsupported";
  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    maxFiles: 1,
    multiple: false,
    disabled: zoneDisabled
  });

  let content: ReactNode | null = null;
  switch (status.state) {
    case "selected":
    // Fallthrough
    case "permission": {
      content = (
        <Button
          primary
          onClick={handleFlash}
          disabled={status.state === "permission"}
        >
          Send to micro:bit
        </Button>
      );
      break;
    }
    case "complete":
    // Fallthrough
    case "error": {
      content = (
        <Button primary onClick={handleStartAgain}>
          Start again
        </Button>
      );
      break;
    }
    case "in-progress": {
      content = (
        <>
          <VisuallyHidden>
            <label htmlFor="progress">Flash progress</label>
          </VisuallyHidden>
          <progress id="progress" max={1} ref={progressRef} />
        </>
      );
      break;
    }
  }
  return (
    <div className={styles.root}>
      <div
        {...getRootProps()}
        className={classNames(styles.zone, !zoneDisabled && styles.enabled)}
      >
        <VisuallyHidden>
          <label htmlFor="file">File to send to the micro:bit</label>
        </VisuallyHidden>
        <input id="file" {...getInputProps()} />
        {status.message && (
          <div aria-live="polite">
            <Text className={styles.status} variant="subtitle">
              {status.message}
            </Text>
          </div>
        )}
        {content}
      </div>
    </div>
  );
};

export default HexFlashTool;
